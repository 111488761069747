





















































.logo {
  margin: 0.2rem 0;
  text-align: center;
  img {
    width: 1rem;
  }
}











































































































.nav {
    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;

        >li,
        a {
			margin: 0 0.25rem;
            color: #333333;
            font-size: 0.24rem;
            border-bottom: 0.01rem solid transparent;
            padding-bottom: 0.2rem;
            cursor: pointer;
            position: relative;
			&:hover {
                border-bottom: 0.01rem solid #FF1932;
                color: #FF1932;

                ol {
                    display: block;
                }

                &:last-child {
                    border-bottom: 0.01rem solid transparent;
                    color: #333333;
                }
            }
            // 二级菜单
            ol {
                display: none;
                background-color: #fff;
                position: absolute;
                min-width: 3rem;
                max-width: 8rem;
                left: 0;
                top: 0.54rem;
                z-index: 9999;
                border: 1px solid #ddd;

                li {
                    height: .5rem;
                    line-height: .5rem;

                    a {
                        max-width: 8rem;
                        display: block;
                        width: 100%;
						height: 100%;
						margin-left: 0;
						padding-left: .25rem;

                        &:hover {
                            color: #FF1932!important;
                            background-color: #eee;
                        }

                    }
                }
            }

            

            &:nth-last-child(2) {
                div {
                    ol {
                        left: auto;
                        right: 0;
                    }
                }
            }
        }
    }
}

.mainNav {
    position: absolute;
    top: 1.8rem;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 999999;
    background-color: rgba(0, 0, 0, 0.4);

    .wCenter {
        background-color: #fff;
    }

    /* 页面导航 s*/
    &__nav {
        padding: 0 0.2rem 0.4rem 0.2rem;

        li {
            min-height: .8rem;
            padding-top: .4rem;

            >a,
            >span {
                display: inline-block;
                font-weight: bold;
                font-size: 0.36rem;
                text-align: center;
                color: #e50113;
                // padding: 0 0.12rem;
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: keep-all;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:hover,
                &.active {
                    color: #e50113;
                }
            }

            &.active {
                a {
                    color: #e50113;
                }
            }

            .iconDown {
                transition: all 0.2s linear;

                &.collapse {
                    transform: rotate(180deg);
                }
            }
        }

        >li {
            position: relative;

            >a:after {
                content: "";
                display: inline-block;
                width: 100%;
                height: 0.04rem;
                background-color: transparent;
                position: absolute;
                bottom: -0.2rem;
                left: 0;
            }

            // 二级菜单
            ol {
                //background-color: rgba(240, 240, 240, 1);
                width: 100%;
                z-index: 9999;
                position: relative;
                top: .1rem;

                li {

                    line-height: 0.5rem;
                    height: 0.5rem;
                    padding-left: 0.4rem;
                }
            }

            &:hover {
                ol {
                    display: block;
                }
            }

            >a:hover:after,
            >a.active:after {
                background-color: #e50113;
            }
        }
    }
}







































































































.foot {
	width: 100%;
	background: #333333;
	padding: .5rem 0 0.3rem;
	margin-top: .5rem;

	@media (max-width: 767px) {
		padding: 1rem 0 0.2rem 0;
	}

	.footMain {
		display: flex;
		justify-content: center;
		align-items: center;

		@media (max-width: 767px) {
			display: block;
		}

		.footLogo {
			width: 1rem;
			margin-right: 0.8rem;

			@media (max-width: 767px) {
				margin-left: auto;
				margin-right: auto;
				padding-bottom: 1rem;
				width: 1.8rem;

			}

			img {
				max-width: 100%;
			}
		}

		.footText {
			width: 6.8rem;
			font-size: 0.16rem;
			color: #ffffff;
			margin-right: 2rem;

			@media (max-width: 767px) {
				width: 100%;
				text-align: justify;
			}

			.text {
				margin-bottom: 0.6rem;

				@media (max-width: 767px) {
					width: 88%;
					margin-left: auto;
					margin-right: auto;
					font-size: 0.32rem;
				}

				a {
					color: inherit;
				}
			}
		}

		.footNav {
			color: #ffb9a0;
			font-size: 0.18rem;

			a {
				color: inherit;
			}

			@media (max-width: 767px) {
				text-align: center;
				font-size: 0.38rem
			}

			span {
				padding: 0 0.2rem;
				border-right: 1px solid #ffb9a0;

				a {
					color: inherit;
				}
			}
			span:last-child{
				border-right: 0;
			}
		}

		.codeMain {
			width: 2.4rem;

			@media (max-width: 767px) {
				width: 50%;
				margin-left: auto;
				margin-right: auto;
				text-align: center;
			}

			.title {
				color: #ffffff;
				font-size: 0.2rem;
				margin-bottom: 0.3rem;

				@media (max-width: 767px) {
					display: none;
				}
			}

			.codeContent {
				display: flex;
				justify-content: space-between;
				align-items: center;

				@media (max-width:767px) {
					margin-top: 0.9rem;

					&.singleWap {
						justify-content: center;
						margin-top: 0.6rem;
					}
				}

				div {
					width: 1rem;

					@media (max-width: 767px) {
						width: 2rem;
					}
				}

				p {
					text-align: center;
					color: #ffffff;
					font-size: 0.18rem;
					margin-top: 0.1rem;

					a {
						color: inherit;
					}

					@media (max-width: 767px) {
						font-size: 0.32rem;
					}
				}
			}
		}
	}

	.bottom {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: row-reverse;
		margin-top: .2rem;

		@media (max-width: 767px) {
			display: block;
			margin-top: 0.8rem;
		}

		.bottomLeft {
			// width: 6.5rem;
			width: 100%;
			color: #ffffff;
			font-size: 0.16rem;
			text-align: center;

			a {
				color: inherit;
			}

			@media (max-width: 767px) {
				width: 100%;
				text-align: center;
				font-size: 0.32rem;
			}
		}

		.bottomRight {
			width: 6.5rem;
			font-size: 0.16rem;
			text-align: right;

			@media (max-width: 767px) {
				width: 100%;
				text-align: center;
				margin-bottom: 0.3rem;
			}

			span {
				margin-left: 0.5rem;
				color: #ffffff;
				cursor: pointer;

				a {
					color: inherit;
				}
			}

			span:hover {
				color: #ffb9a0;
			}
		}
	}
}

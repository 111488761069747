/**
 * 全局样式控制
 * web端和wap端
 */
[v-cloak] {
  display: none;
}
/* 手机端 */
@media (max-width: 767px) {
  // 手机端隐藏
  .xs-hide {
    display: none!important;
  }
}

/* 非手机端 */
@media (min-width: 768px) {
  // 非手机端隐藏
  .sm-hide {
    display: none!important;
  }
}

@mixin bgg($bg:"@/img/wap/settings/bgViceChairmanUnit.png") {
  background: url($bg) center top / cover no-repeat;
}
@mixin bgc($bg) {
  background-color: $bg;
}

@mixin poa {
  position: absolute;
}
.poa {
  position: absolute;
}
.por {
  position: relative;
}

.fb {
  font-weight: bold;
}
.hand{
  cursor: pointer;
}
.block{
  display: block;
}
.mb30{
  margin-bottom: 30px;
}
.mb50{
  margin-bottom: 50px;
}





























































$baseBlue: #e50113;

.topRight__box {
	max-width: .8rem;

	.user {
		display: inline-block;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}

.top {
	margin-top: .1rem;
	height: 1.8rem;
	.wCenter {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 1.8rem;
		.top_l {
			color: $baseBlue;
			display: flex;
			align-items: center;
			flex: 0 0 1.2rem;
			&_txt {
				font-size: .24rem;
				margin-left: .04rem;
			}
		}

		.top_c {
			// position: absolute;
			// left: 50%;
			// margin-left: -.6rem;

			&_logo {
				display: flex;
				align-items: center;
				width: 1.5rem;
			}
		}

		.top_r {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex: 0 0 1.2rem;
			.img {
				$wh:.9rem;
				width: $wh;
				height: $wh;
				margin-right: .2rem;
				margin-top: .05rem;
			}
		}

		.ml_2 {
			margin-left: .2rem;
		}
	}
}

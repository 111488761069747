/**
 * 全局样式控制
 * web端和wap端
 */


  html{font-size:100px;}
  .wCenter{
  	width: 13rem;
  	margin: 0 auto;
  	@media (max-width:767px){
  		width: 100%;
		padding-left: 0.2rem;
		padding-right: 0.2rem;
		overflow: hidden;
  	}
  }
  /* 顶部 e */
  
  /* 轮播图箭头样式 s*/
  .swiper-button-prev, .swiper-button-next{
	     margin-top: -0.3rem; 
  }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
  	background-image: url(../assets/pre.png);
  	background-size: 100% 100%;
  	width: 0.6rem;
  	height: 0.6rem;
  }
  
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
  	background-image: url(../assets/next.png);
  	background-size: 100% 100%;
  	width: 0.6rem;
  	height: 0.6rem;
  }
  .swiper-button-next:after, .swiper-button-prev:after{
	  display: none;
  }
   /* 轮播图箭头样式 e*/


// 留资弹框
.formModal {
  position: fixed;
  left: 50%;
  top: 5%;
  margin-left: -4rem;
  width: 8rem;
  background: #eeeeee;
  padding: 0.5rem 0.6rem;
  margin-top: 0.7rem;
  z-index: 99999;
}
a{
  color: unset;
}
// 分页手机端隐藏跳页
.ivu-page-options{
  @media (max-width:767px){
    display: none;
  }
}

.red{
  color: #FF1933!important;
}


.picCenter{
	display: flex;
	align-items: center;
	justify-items: center;
	width: 100%;
	height: 100%;
	flex-direction:column;
}

/*图片懒加载*/
	.lazyPic[lazy=loading] {
		width: 3.2rem;
		height: 2.1rem;
	}

	.lazyPic[lazy=error] {
		width: 2.16rem;
		height: 1.2rem;
	}

	.lazyPic[lazy=loaded] {
		width: 100%;
	}
/*图片懒加载*/
.tabFoot{
	margin-top: .5rem;
}
/* swiper 图片点击放大 背景色 */
.pswp__bg{
	opacity: .6!important;
}
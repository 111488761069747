















  .demo-spin-icon-load{
        animation: ani-demo-spin 1s linear infinite;
    }
    @keyframes ani-demo-spin {
        from { transform: rotate(0deg);}
        50%  { transform: rotate(180deg);}
        to   { transform: rotate(360deg);}
    }
   .cred{
	   color: #ff1932;
   }
   .ivu-spin-fix{
	   position: fixed!important;
		z-index: 9999999!important;
   }
